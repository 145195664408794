import {
  createStore
} from 'vuex'
import mounts from './modules/mounts'
import files from './modules/files'

export default createStore({
  modules: {
    mounts: mounts,
    files: files
  }
})
