import {
  EMPTY_OBJ
} from '@vue/shared';
import ApiService from '../../services/ApiService';
import $socket from '../../socket/socket'
const api = new ApiService();

export default {
  namespaced: true,
  state: () => ({
    selectedEditFiles: [],
    actionState: {},
    previewUrl: null,
    isUploading: false,
    selectedEditVideo: null,
    isDownloading: false,
    isTrimming: false,
    isConcatting: false
  }),

  getters: {
    checkIfVideo: (state, getters, rootState, rootGetters) => (item) => {
      return Object.keys(item).length > 0 && item.stats.chmod.file
    },
    returnFileSource: (state, getters, rootState, rootGetters) => (item) => {
      if (getters.checkIfVideo(item)) {
        return process.env.VUE_APP_FRONTEND + 'pd/' + item.name + item.path
      } else {
        return null
      }
    }
  },
  actions: {
    setSelectedEditVideo({
      commit
    }, video) {
      commit('SET_SELECTED_EDIT_VIDEO', video)
    },
    downloadFileRequest({
      commit,
      state
    }, file) {
      return new Promise(() => {
        // make api call
        commit("SET_IS_DOWNLOADING", true)
        api.downloadFileRequest(file).then(
          response => {
            commit("SET_IS_DOWNLOADING", false)
            if (response.status === 200) {
              const objectUrl = window.URL.createObjectURL(response.data);

              const link = document.createElement('a')
              link.style.display = "none";
              link.href = objectUrl

              link.download = file.file
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          }
        )
      })
    },
    uploadFileRequest({
      commit,
      state
    }, fileData) {
      commit('SET_FILE_IS_UPLOADING', true)
      return new Promise(() => {
        // make api call
        api.uploadFileRequest(fileData).then(
          response => {
            if (response.status === 201) {
              commit('SET_FILE_IS_UPLOADING', false)
              commit('ACTION_RESULT', {
                result: 'success',
                message: 'Files were successfully uploaded',
                title: 'Upload'
              })
            } else {
              commit('SET_FILE_IS_UPLOADING', false)
              commit('ACTION_RESULT', {
                result: 'danger',
                message: 'Files could not be uploaded',
                title: 'Upload'
              })
            }
          }
        )
      })
    },
    setSelectedFile({
      commit,
      state
    }, file) {
      if (file && Object.keys(file).length > 0) {
        if (!state.selectedEditFiles.filter(function (e) {
            return e.name === file.name;
          }).length > 0) {
          commit('ADD_SELECTED_FILE', file)
        }

      }
    },
    trimFileRequest({
      commit
    }, file) {
      commit("SET_IS_TRIMMING", true)
      $socket.emit('trim', {
        media: '/mnt/' + file.path,
        start: file.markIn,
        duration: file.markOut - file.markIn,
        output: "/mnt/exports/" + file.output,
        email: file.email
      })
    },
    SOCKET_trim({
      commit
    }, result) {
      commit("SET_IS_TRIMMING", false)
      if (result.job && !result.error) {
        commit('ACTION_RESULT', {
          result: 'success',
          message: 'File was successfully trimmed',
          title: 'trim'
        })
        let output = result.job.data.output.replace('/mnt/', '')
        let previewUrl = process.env.VUE_APP_FRONTEND + 'pd/' + output

        commit('SET_PREVIEW_URL', previewUrl)
      } else {
        commit('ACTION_RESULT', {
          result: 'danger',
          message: 'File trim failed',
          title: 'trim'
        })
      }
    },
    SOCKET_concat({
      commit
    }, result) {
      commit("SET_IS_CONCATTING", false)
      if (result.job && !result.error) {
        commit('ACTION_RESULT', {
          result: 'success',
          message: 'File was successfully concatted',
          title: 'concat'
        })
        let output = result.job.data.output.replace('/mnt/', '')
        let previewUrl = process.env.VUE_APP_FRONTEND + 'pd/' + output

        commit('SET_PREVIEW_URL', previewUrl)
      } else {
        commit('ACTION_RESULT', {
          result: 'danger',
          message: 'File concat failed',
          title: 'concat'
        })
      }
    },
    SOCKET_removeFile({
      commit,
      dispatch,
      rootState
    }, result) {
      if (result.job && !result.error) {
        dispatch('mounts/fetchFileList', rootState.mounts.previousFolder, {
          root: true
        })

        dispatch('mounts/setSelectedItem', {}, {
          root: true
        })
        commit('ACTION_RESULT', {
          result: 'success',
          message: 'File was successfully deleted',
          title: 'deletion'
        })
      } else {
        commit('ACTION_RESULT', {
          result: 'danger',
          message: 'File could not be deleted.',
          title: 'deletion'
        })
      }

    },
    concatFileRequest({
      commit
    }, files) {
      commit("SET_IS_CONCATTING", true)
      $socket.emit('concat', {
        media: files.media,
        output: "/mnt/exports/" + files.output,
        email: files.email
      })
    },
    deleteFile({
      commit
    }, file) {
      $socket.emit('removeFile', {
        path: file.path,
        file: file.file,
        mount: file.mount
      })

    },
    removeFromEdit({
      commit,
      state
    }, file) {
      let newEdites = state.selectedEditFiles.filter(function (obj) {
        return obj.name !== file.name;
      });
      if (state.selectedEditVideo && state.selectedEditVideo.name === file.name) {
        if (newEdites.length > 0) {
          commit('SET_SELECTED_EDIT_VIDEO', newEdites[0])
        } else {
          commit('SET_SELECTED_EDIT_VIDEO', null)
        }

      }
      commit('SET_EDIT_FILES', newEdites)
    },
    addPlayerToEditFile({
      commit,
      state
    }, file) {
      for (let i = 0; i < state.selectedEditFiles.length; i++) {
        if (state.selectedEditFiles[i].name === file.name) {
          state.selectedEditFiles[i].player = file.player;
        }
      }
    },
    setIsDownloading({
      commit
    }, value) {
      commit('SET_IS_DOWNLOADING', value)
    }
  },
  mutations: {
    SET_FILE_IS_UPLOADING(state, bool) {
      state.isUploading = bool
    },
    SET_PREVIEW_URL(state, url) {
      state.previewUrl = url
    },
    SET_EDIT_FILES(state, files) {
      state.selectedEditFiles = files
      state.selectedEditVideo = files[0]
    },
    ADD_SELECTED_FILE(state, item) {
      state.selectedEditFiles.push(item)
      state.selectedEditVideo = state.selectedEditFiles[0]
    },
    ACTION_RESULT(state, result) {
      state.actionState = result
    },
    SET_SELECTED_EDIT_VIDEO(state, value) {
      state.selectedEditVideo = value
    },
    SET_IS_DOWNLOADING(state, value) {
      state.isDownloading = value
    },
    SET_IS_TRIMMING(state, value) {
      state.isTrimming = value
    },
    SET_IS_CONCATTING(state, value) {
      state.isConcatting = value
    }
  }
}
