export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'fileexplorer',
      displayName: 'menu.fileexplorer',
      meta: {
        icon: 'fa-folder',
      },
    },
    {
      name: 'editor',
      displayName: 'menu.editor',
      meta: {
        icon: 'vuestic-iconset-forms',
      },
    },
    {
      name: 'fileupload',
      displayName: 'menu.fileupload',
      meta: {
        icon: 'fa-upload',
      },
    },
  ],
}
/* {
  name: 'maps',
  displayName: 'menu.maps',
  meta: {
    icon: 'vuestic-iconset-maps',
  },
  disabled: true,
  children: [
    {
      name: 'google-maps',
      displayName: 'menu.google-maps',
    },
    {
      name: 'yandex-maps',
      displayName: 'menu.yandex-maps',
    },
    {
      name: 'leaflet-maps',
      displayName: 'menu.leaflet-maps',
    },
    {
      name: 'bubble-maps',
      displayName: 'menu.bubble-maps',
    },
    {
      name: 'line-maps',
      displayName: 'menu.line-maps',
    },
  ],
},
{
  name: 'pages',
  displayName: 'menu.pages',
  meta: {
    icon: 'vuestic-iconset-files',
  },
  disabled: true,
  children: [
    {
      name: 'login',
      displayName: 'menu.login-singup',
    },
    {
      name: '404-pages',
      displayName: 'menu.404-pages',
    },
    {
      name: 'faq',
      displayName: 'menu.faq',
    },
  ],
}, */