import axios from 'axios'

export default class ApiService {

  createAxiosInstance() {
    const axionsInstance = axios.create({
      baseURL: process.env.VUE_APP_BACKEND
    })

    return axionsInstance

  }

  getMounts() {
    return this.createAxiosInstance().get('api/mounts')
  }

  removeMount(mount) {
    return this.createAxiosInstance().delete('api/mounts/' + mount._id)
  }

  addMount(mount) {
    return this.createAxiosInstance().post('api/mounts', {
      "path": mount.path,
      "type": mount.type,
      "name": mount.name + Math.floor(Math.random() * 10),
      "provider": mount.provider
    })
  }

  requestMountPath(oPath) {
    return this.createAxiosInstance().post('api/io/media/directory', {
      "path": "",
      "mount": oPath.mount,
    })
  }

  downloadFileRequest(request) {
    return this.createAxiosInstance().post('api/media/download', {
      "file": request.file,
      "path": request.path,
      "mount": request.mount,
    }, {
      responseType: 'blob'
    })
  }

  uploadFileRequest(fileData) {
    return this.createAxiosInstance().post('api/uploads', fileData)
  }
}
