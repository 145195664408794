import { VuesticPlugin } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSocketIOExt from 'vue-socket.io-extended';
import io from 'socket.io-client';
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import $socket from './socket/socket'
import 'video.js/dist/video-js.css';
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import moment from 'moment'

const i18nConfig = {
  locale: 'nl',
  fallbackLocale: 'en',
  messages: {
    en: require('@/i18n/en.json'),
    ch: require('@/i18n/cn.json'),
    es: require('@/i18n/es.json'),
    ir: require('@/i18n/ir.json'),
    br: require('@/i18n/br.json'),
    nl: require('@/i18n/nl.json')
  }
}

const app = createApp(App)

app.use(store)

app.use(VueSocketIOExt, $socket, {
  store,
  actionPrefix: "SOCKET_",
  mutationPrefix: "SOCKET_"
})

app.use(moshaToast)
app.use(router)


if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  app.use(createGtm(gtmConfig))
}

app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)

app.mount('#app')



