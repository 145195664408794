import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AppLayout from '@/layout/app-layout.vue'
import UIRoute from '@/pages/admin/ui/route'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'fileexplorer' },
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'fileexplorer',
        path: 'fileexplorer',
        component: () => import('@/pages/admin/fileexplorer/fileexplorer.vue'),
      },
      {
        name: 'editor',
        path: 'editor',
        component: () => import('@/pages/admin/fileeditor/fileeditor.vue'),
      },
      {
        name: 'fileupload',
        path: 'fileupload',
        component: () => import('@/pages/admin/fileupload/fileupload.vue'),
      },
      UIRoute,
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

export default router
