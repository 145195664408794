import {
  EMPTY_OBJ
} from '@vue/shared';
import ApiService from '../../services/ApiService';
import $socket from '../../socket/socket'
const api = new ApiService();

export default {
  namespaced: true,
  state: () => ({
    mounts: {},
    selectedMount: {},
    selectedItem: {},
    isMount: false,
    previousFolder: null
  }),

  getters: {
    getSelectedItem: (state) => {
      return state.selectedItem
    }
  },
  actions: {
    setSelectedItem({
      commit
    }, item) {
      commit('SET_SELECTED_ITEM', item)
    },
    fetchMounts({
      commit,
    }) {
      return new Promise((resolve) => {
        // make api call
        api.getMounts().then(
          response => {
            commit('SET_MOUNTS', response.data)
            resolve()
          }
        )
      })
    },
    removeMounts({
      commit,
      state
    }, mounts) {
      mounts.forEach(mount => {
        return new Promise(() => {
          // make api call
          api.removeMount(mount).then(
            response => {
              if (response.status === 200) {
                const updatedMounts = state.mounts.filter(item => item._id !== mount._id)

                commit('SET_MOUNTS', updatedMounts)
              }
            }
          )
        })
      });

    },
    addNewMount({
      commit,
      state
    }, newMount) {
      return new Promise(() => {
        // make api call
        api.addMount(newMount).then(
          response => {
            if (response.status === 201) {
              const newMounts = state.mounts
              newMounts.push(response.data)

              commit('SET_MOUNTS', newMounts)
            }
            resolve(response);
          }
        )
      })
    },
    fetchFileList({
      commit,
      state
    }, folder) {

      commit('SET_PREVIOUS_FOLDER', folder)
      let path = ''
      if (folder.topLevel === false || !folder.topLevel) {
        path = folder.path

        commit('SET_SELECTED_ITEM', folder)
        state.selectedMount.subListActive = true
        state.isMount = false
      } else {
        state.selectedMount.subListActive = false
        commit('SET_SELECTED_MOUNT', folder)
        commit('SET_SELECTED_ITEM', {})
        state.selectedMount.isActive = !state.selectedMount.isActive
        state.isMount = true
      }
      $socket.emit('statDirectory', {
        mount: folder._id,
        path: path
      })
    },
    SOCKET_listMounts({
      commit
    }, mounts) {
      mounts.forEach(mount => {
        mount.isActive = false
      })
      commit('SET_MOUNTS', mounts)
    },
    SOCKET_listDirectory({
      commit
    }, dirs) {},
    SOCKET_statDirectory({
      state,
      commit
    }, dirStat) {
      if (!dirStat.error) {
        let dirs = []
        dirStat.data.entries.forEach(entry => {

          let path = '/' + entry.name

          if (Object.keys(state.selectedItem).length > 0) {
            path = state.selectedItem.path + '/' + entry.name
          }
          entry.path = path
          entry.mountId = state.selectedMount._id
          entry._id = state.selectedMount._id
          entry.fullPath = state.selectedMount.name + entry.path
          entry.source = process.env.VUE_APP_FRONTEND + 'pd/' + entry.fullPath
          if (entry.stats && entry.stats.chmod.file) {
            entry.isFile = true
          } else entry.isFile = false
          dirs.push(entry)
        })

        if (state.isMount) {
          commit('SET_FILE_LIST', dirs)
        } else {
          commit('SET_SUBFOLDER_LIST', dirs)
        }

      }
    }
  },
  mutations: {
    SET_MOUNTS(state, mounts) {
      // `state` is the local module state
      state.mounts = mounts
    },
    SET_SELECTED_MOUNT(state, mount) {
      state.selectedMount = mount
    },
    SET_FILE_LIST(state, list) {
      state.selectedMount.children = list
    },
    SET_SUBFOLDER_LIST(state, list) {
      state.selectedItem.children = list
    },
    SET_SELECTED_ITEM(state, item) {
      state.selectedItem = item
    },
    SET_PREVIOUS_FOLDER(state, folder) {
      state.previousFolder = folder
    }
  }
}
